<template>
  <div>
    <div
      v-if="shouldShowNewsletterBox"
      ref="newsLetter"
      id="validation-error"
      class="py-56 bg-grey600"
    >
      <div class="layout-container">
        <div v-if="newsletterIsSubscribed">
          <div class="mb-12 text-grey200 md:heading-md-text heading-sm-text">
            {{ footerConfig?.newsLetter.successHeading }}
          </div>
          <div class="mb-12 text-grey400 preamble-thin-text">
            {{ footerConfig?.newsLetter.successMessage }}
          </div>
        </div>
        <div v-else class="md:w-3/5">
          <div class="mb-12 text-grey400 preamble-thin-text">
            {{ footerConfig?.newsLetter.byline }}
          </div>
          <div class="mb-12 md:heading-md-text heading-sm-text text-grey200">
            {{ footerConfig?.newsLetter.heading }}
          </div>
          <form autocomplete="off">
            <TextInput
              :id="'footerNewsletterField'"
              :placeholder="$lang('footerResources', 'emailAddress')"
              :error="emailError"
              :type="'email'"
              :form-input="false"
              :autocomplete="'off'"
              :tabindex="-1"
              @submitInput="onEmailChange"
              @enter-submit="onSubmitNewsLetter"
            />
            <div class="flex mt-16">
              <WysiwygWrapper
                :style-config="{ 'wysiwyg text-base text-grey200': true }"
                :html="footerConfig?.newsLetter.termsAndConditions"
              ></WysiwygWrapper>
            </div>
            <Button
              class="mt-24"
              :text="footerConfig?.newsLetter.buttonText"
              :size="Sizes.md"
              :theme="Themes.light"
              :loading="newsletterSubmitLoading"
              @click="onSubmitNewsLetter"
            />
          </form>
          <div v-if="newsletterError" class="mt-16 meta-info-text text-error">
            {{ newsletterError }}
          </div>
        </div>
      </div>
    </div>
    <div class="py-56 bg-grey800">
      <div class="layout-container">
        <div class="md:mb-24 mb-32 flex flex-col md:flex-row justify-between">
          <div class="flex flex-col md:flex-row justify-between flex-grow">
            <div class="md:mr-112 md:mb-0 mb-12">
              <div
                v-for="mainMenuItem in footerConfig?.mainMenu"
                :key="`mainMenuFooter-${mainMenuItem.text}`"
                class="mb-16 cursor-pointer select-none"
              >
                <nuxt-link
                  :to="mainMenuItem.href"
                  :target="mainMenuItem.target"
                  :title="mainMenuItem.title"
                  class="sub-heading-lg-text text-grey500"
                >
                  {{ mainMenuItem.text }}
                </nuxt-link>
              </div>
            </div>
            <div class="md:mr-auto flex md:flex-row flex-col flex-1">
              <MenuFoldOut
                v-for="subMenu in footerConfig?.subMenu"
                :id="'subMenuMobileFooter' + subMenu.heading"
                :key="'subMenuMobileFooter' + subMenu.heading"
                :heading="subMenu.heading"
                :toggled-row="toggledMenuRow"
                :items="subMenu.links"
                :color-scheme="'dark'"
                :is-mobile="isMobile"
                class="md:max-w-208 md:w-full md:mr-24"
                @toggleMenuRow="toggleMenuRow"
              />
            </div>
          </div>
          <div class="flex-shrink-0 md:flex md:flex-col md:justify-between">
            <div class="flex mb-40 md:justify-end md:mt-0 mt-56">
              <a
                v-for="link in footerConfig?.socialLinks"
                :key="'footerSocialMediaLinks-' + link.linkUrl"
                class="h-32 w-32 bg-grey600 flex justify-center items-center text-grey200 rounded-full md:last:mr-0 mr-16"
                :href="link.linkUrl"
                target="_blank"
                rel="noopener"
              >
                <ClientOnly>
                  <fa
                    :icon="['fab', link.iconCssClass]"
                    :aria-label="link.iconCssClass"
                  ></fa>
                </ClientOnly>
              </a>
            </div>
            <div v-if="purchaseEnabled">
              <div class="text-grey200 base-medium-text">
                {{ $lang('footerResources', 'paymentOptionsHeader') }}:
              </div>
              <div class="flex pt-24 md:mb-12 flex-wrap">
                <img
                  v-for="logo in footerConfig?.paymentLogos"
                  :key="'footerPaymentLogo-' + logo"
                  class="max-h-24 md:last:mr-0 mr-24 mb-16"
                  :src="logo"
                  height="24"
                  alt="Footer payment logo"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="shouldShowMarketDisplay" class="flex">
          <MarketDisplay :color-scheme="'dark'" />
        </div>
        <div class="flex md:flex-row flex-col md:justify-between md:mb-24">
          <WysiwygWrapper
            :style-config="{
              'small-base-regular-text text-grey400 md:mb-0 mb-40 md:mt-2': true,
            }"
            :html="footerConfig?.companyInfo ?? ''"
          ></WysiwygWrapper>
          <div class="flex flex-shrink-0 justify-end md:flex-row flex-col">
            <div v-for="metaLink in footerConfig?.metaLinks"
                 :key="'metaMenuFooterItem-' + metaLink.text"
                 class="md:mb-0 mb-12 last:mb-0 md:ml-32 cursor-pointer select-none">
              <nuxt-link :to="metaLink.href"
                         :target="metaLink.target"
                         :title="metaLink.title"
                         class="small-base-regular-text font-light text-grey400">
                {{ metaLink.text }}
              </nuxt-link>
            </div>
            <ClientOnly>
              <div v-if="isOneTrustLoaded"
                  class="md:mb-0 mb-12 last:mb-0 md:ml-32 cursor-pointer select-none">
                <a data-onetrust="v3" id="ot-sdk-btn" rel="noopener noreferrer"
                  class="small-base-regular-text font-light text-grey400 optanon-toggle-display">
                  {{ $lang('footerResources', 'cookieSettingsHeader') }}
                </a>
              </div>
            </ClientOnly>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoggedInAsAdmin" class="pb-80 bg-grey800">
      <div class="layout-container text-white">
        <div
          class="btn btn--md"
          :class="{
            'btn--light-theme': testModeActive,
            'btn--light-ghost-theme': !testModeActive,
          }"
          @click="toggleTestMode"
        >
          <span v-if="testModeActive">{{
            $lang('sharedResources', 'deactivateTestMode')
          }}</span>
          <span v-else>{{ $lang('sharedResources', 'activateTestMode') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import MarketDisplay from '../MarketDisplay.vue';
import MenuFoldOut from '../body/MenuFoldOut.vue';
import TextInput from '../form-elements/TextInput.vue';
import Button from '../globals/Button.vue';
import WysiwygWrapper from '../WysiwygWrapper.vue';
import { type IMarket } from '~/models/api-types';
import { Sizes, Themes } from '~/constants/button-layouts';
import { useGlobalContentStore } from '~/store/globalContent';
import { useVoyadoStore } from '~/store/voyado';
import * as Sentry from '@sentry/vue';

const { $event } = useNuxtApp();
const config = useGlobalContentStore().config;
const voyadoStore = useVoyadoStore();

const { apiPost, apiPut, apiDelete } = useApiFetch();
const { $lang } = useNuxtApp();

defineProps<{
  isMobile?: boolean,
}>();

const testModeActive = useCookie('test_order_active');
const toggledMenuRow = ref('');
const emailError = ref('');
const newsletterEmail = ref('');
const newsletterError = ref('');
const newsletterIsSubscribed = ref(false);
const newsletterSubmitLoading = ref(false);
const cookiebotActive = ref(false);

const footerConfig = computed(() => {
  return config.footer;
});

const shouldShowNewsletterBox = computed(() => {
  return !!config.currentMarket?.enableNewsletter && !!!config.page?.hideNewsletterFooter;
});

const shouldShowMarketDisplay = computed(() => {
  return config.markets?.length > 1;
});

const purchaseEnabled = computed(() => {
  return config.currentMarket.enablePurchases;
});

const isLoggedInAsAdmin = computed(() => {
  return config.isLoggedInAsAdmin;
});

onMounted(() => {
  if (testModeActive.value && !isLoggedInAsAdmin.value) {
    deactivateTestMode();
  }
});

const isOneTrustLoaded = computed(() => {
  const url = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
  if (typeof window !== 'undefined') {
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
      if (scripts[i].src.includes(url)) return true;
    }
  }
  return false;
});

const toggleMenuRow = (id: string) => {
  if (toggledMenuRow.value === id) {
    toggledMenuRow.value = '';
  } else {
    toggledMenuRow.value = id;
  }
};

const onEmailChange = (input: string) => {
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.trim()) && input) {
    emailError.value = $lang('footerResources', 'invalidEmail');
  } else {
    emailError.value = '';
  }
  newsletterEmail.value = input;
};

const onSubmitNewsLetter = async() => {
  if (!!newsletterEmail.value && !emailError.value) {
    try {
      newsletterSubmitLoading.value = true;
      const { currentMarket, language } = config;
      const { countryCode, useVoyado } = currentMarket as IMarket;
      if (useVoyado) {
        const voyadoRes = await apiPost<string>(
          `voyado/newsletter?countryCode=${countryCode}&language=${language}`,
          {
            email: newsletterEmail.value,
            countryCode,
            language,
          }
        );

        voyadoStore.setContactId(voyadoRes);
        newsletterError.value = '';
        newsletterIsSubscribed.value = true;
        window.dataLayer?.push({
          event: 'newsletter_signup',
        });
        scrollToSection();
      }
    } catch (error) {
      Sentry.captureException(error)
      const message = error.response?.data?.message || '';
      $event('trigger-error-bar', {
        message
      });
    } finally {
      newsletterSubmitLoading.value = false;
    }
  } else {
    newsletterError.value = $lang('footerResources', 'incompleteData');
  }
};

const emit = defineEmits<{
  (event: 'setTestMode', value: string | null): void,
}>();

const toggleTestMode = async() => {
  try {
    if (testModeActive.value) {
      await apiDelete(`user/session/testmode?language=${config.language}&countryCode=${config.currentMarket?.countryCode}`);
      testModeActive.value = null;
    } else {
      await apiPut(
        `user/session/testmode?language=${config.language}&countryCode=${config.currentMarket?.countryCode}`
      );
      testModeActive.value = 'true';
    }
    emit('setTestMode', testModeActive.value);

  } catch(e) {
    console.log(e);
  }
};

const deactivateTestMode = async() => {
  try {
    await apiDelete(`user/session/testmode?language=${config.language}&countryCode=${config.currentMarket?.countryCode}`);
    testModeActive.value = null;
  } catch (e) {
    console.log(e);
  }
};

const scrollToSection = () => {
  const element = document.getElementById('newsletter');

  if (element) {
    const offset = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: offset - 140,
      left: 0,
      behavior: 'smooth',
    });
  }
};

defineExpose({
  toggleTestMode,
});
</script>
